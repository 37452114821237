*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Roboto Mono', monospace;   
  text-transform: uppercase;
  color: rgb(25, 32, 41); 
  padding-bottom: 3rem;                                       
}
a{
  color: rgb(25, 32, 41);
}
a:hover{
  text-decoration: none;
}
img{
  width: 100%;
  object-fit: cover;
}
::selection {
  color: white;
  background-color: black;
}
